/* eslint-disable  */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { SpinnerDotted } from 'spinners-react';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { Entry } from '../../redux/entrySlice';
import styles from './editUser.module.scss';
import { TableContext } from '../../contexts/tableContext';
import backImg from '../../assets/back_arrow.svg';
import userImg from '../../assets/userImg.png';
import closeEye from '../../assets/close_eye.svg';
import 'react-toastify/dist/ReactToastify.css';
import { getCookies } from '../../hooks/useCookies';
import EmailInput from '../../components/EmailBox/EmailBox';
import TextInput from '../../components/TextField/TextField';
import PasswordField from '../../components/PasswordField/PasswordField';

const UserEdit = () => {
  let userId;
  const [submitting, setSubmitting] = useState(false);
  const { dashboardStyle, bodyStyle, formthemeStyle, setDashboardHeader, errormsgStyle } =
    useContext(TableContext);
  const params = useParams();
  const userrole = getCookies('USERROLE');
  const [perror, setPerror] = useState(null);
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const editFlag = userrole === 'superadministrator';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    userId = params.userId;
    const actionData = { actionUrl: `user/${userId}`, actionMethod: 'get' };
    dispatch(Entry(actionData)).then((resp) => {
      setLoading(false);
      const userDetail = {
        name: resp?.payload?.data?.user?.user?.name,
        email: resp?.payload?.data?.user?.user?.email,
        // phone: resp.payload.data.user.userid.phone,
        // employerName: resp.payload.data.user.createdBy.name,
        password: resp?.payload?.data?.user?.user?.showPassword,
        // gender: resp.payload.data.user.gender,
        // dob: resp.payload.data.user.dob,
        // weight: resp.payload.data.user.weight,
        // height: resp.payload.data.user.height,
      };
      setUser(userDetail);
      reset(userDetail);
    });
  }, [dispatch, params.userId, reset, setDashboardHeader]);

  const deleteUser = async () => {
    try {
      const actionData = { actionUrl: `user/${params.userId}`, actionMethod: 'delete' };
      dispatch(Entry(actionData)).then(() => {
        toast.success('User deleted successfully');
        navigate(-1);
      });
    } catch (err) {
      toast.error('Deletion failed');
      console.error('There was a problem with the delete operation:', err);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure you want to permanently delete this user?',
      buttons: [{ label: 'Yes', onClick: deleteUser }, { label: 'No' }],
    });
  };

  const onSubmitData = (data) => {
    if (data.name.trim().length === 0) {
      document.getElementById('name').focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        name: data?.name,
        email: data?.email,
        password: data?.password,
      };
      data.actionUrl = `user/${params.userId}`;
      data.actionMethod = 'patch';
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          toast.success('User updated successfully');
          navigate(-1);
        } else {
          setError(resp.payload.message);
          toast.error('Error');
        }
      });
    }
  };

  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]} id={styles.dashboardcont2}>
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '160px',
              marginBottom: '160px',
              color: '#7a68c2',
            }}
          >
            <SpinnerDotted style={{ color: '#131F43' }} />
          </div>
        ) : (
          <form className="form_wrapper" onSubmit={handleSubmit(onSubmitData)}>
            <div className="form_head">
              <div
                className={`${styles.arrowback} backBtn`}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Image className="mr-1" src={backImg} alt="Back Arrow" />
                Back
              </div>
              <Button
                className={`${styles.saveBtn} ${styles.createBtn}`}
                type="submit"
                disabled={submitting}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0C5.38125 0 0 5.38125 0 12C0 18.6187 5.38125 24 12 24C18.6187 24 24 18.6187 24 12C24 5.38125 18.6187 0 12 0Z"
                    fill="#38A169"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.8032 7.95468C18.0938 8.24531 18.0938 8.72343 17.8032 9.01406L10.772 16.0453C10.6267 16.1906 10.4345 16.2656 10.2423 16.2656C10.0501 16.2656 9.85791 16.1906 9.7126 16.0453L6.19697 12.5297C5.90635 12.2391 5.90635 11.7609 6.19697 11.4703C6.4876 11.1797 6.96572 11.1797 7.25635 11.4703L10.2423 14.4562L16.7438 7.95468C17.0345 7.65937 17.5126 7.65937 17.8032 7.95468Z"
                    fill="white"
                  />
                </svg>
                {submitting ? 'Please wait..' : 'Save'}
              </Button>
            </div>

            <div className={`${styles[formthemeStyle]} form_container`} id={styles.addform}>
              <div className={styles.details_card_head}>
                <h1>Edit Employee</h1>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Name"
                      fieldName="name"
                      placeHolder="Enter Name"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <EmailInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Email"
                      placeHolder="Enter Email Address"
                      fieldName="email"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <PasswordField
                      fieldName="password"
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Password"
                      register={register}
                      errors={errors}
                      placeHolder="Enter Password"
                      // defaultValue={user?.userid?.showPassword}
                      isRequired
                      minimLength={8}
                      eyeiconstyle={styles.eyeicon}
                      mandatory
                    />
                    {perror && <p className={styles[errormsgStyle]}>{perror}</p>}
                  </div>
                  {/* <div className="col-lg-6 col-md-12 col-sm-12 selectDrp">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="role"
                    label="Role"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={roles}
                    errors={errors}
                    placeholder="Choose Role"
                    mandatory
                  />
                </div> */}
                </div>
                {/* <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <PhoneNumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Phone Number"
                    placeHolder="Phone Number"
                    fieldName="phone"
                    register={register}
                    errors={errors}
                    mandatory
                    isRequired
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 dateRange">
                  <Label className={styles.formlabel}>Date of Birth</Label>
                  <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                  <DatePickerComponent
                    className={styles.dateinputbox}
                    classNamedark={styles.dateinputbox1}
                    control={control}
                    name="dateOfBirth"
                    isRequired
                    errors={errors}
                    label="Date Of Birth"
                  />
                </div>
              </div> */}
                {/* <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <MultipleFileUpload />
                </div>
              </div> */}

                <br />
                {error && (
                  <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                    {error}
                  </h6>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserEdit;
